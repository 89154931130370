import { Component, OnInit } from '@angular/core';
import { LoginService } from './services/login-service/login.service';
import { PermisoService } from './services/permiso-service/permiso.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
  title = 'scc-copy';

  constructor(
    private permisoService: PermisoService,
    private loginService: LoginService
  ) { }

  ngOnInit(): void {
    if(this.loginService.estaAutenticado()) {
      this.permisoService.actualizarPermisos();
    }
  }

}
