import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { EventTypes } from 'src/app/models/event-types';
import { ToastEvent } from 'src/app/models/toast-event';

import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  toastEvents: Observable<ToastEvent>;
  private _toastEvents = new Subject<ToastEvent>();

  constructor(
    private _snackBar: MatSnackBar
  ) {
    this.toastEvents = this._toastEvents.asObservable();
  }

  showSuccessToast(title: string, message: string) {
    // this._toastEvents.next({
    //   message,
    //   title,
    //   type: EventTypes.Success,
    // });
    this.toast(message,'success');
  }

  showErrorToast(title: string, message: string) {
    // this._toastEvents.next({
    //   message,
    //   title,
    //   type: EventTypes.Error,
    // });
    this.toast(message,'error');
  }

  showInfoToast(title: string, message: string) {
    // this._toastEvents.next({
    //   message,
    //   title,
    //   type: EventTypes.Info,
    // });
    this.toast(message,'info');
  }

  private toast(message: string, css: string) {
    let config = new MatSnackBarConfig();

    config.horizontalPosition = 'center';
    config.verticalPosition = 'bottom';
    config.duration = 3000;
    config.panelClass = css;

    this._snackBar.open(message, '', config);
  }
}
