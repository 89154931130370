import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private url: string = environment.url;
  public userToken: string | null = '';

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  login(email: string, password: string) {
    return this.http.post<any>(this.url + 'auth/login', { email, password }).pipe(
      map(resp => {
        this.guardarToken(resp.data.token, resp.data.fecha_expiracion);
        return resp;
      })
    );
  }

  logout() {
    this.http.post<any>(this.url + 'auth/logout', {}).subscribe(data => {
      localStorage.removeItem('token');
      this.router.navigate(['auth/logout']);
    });
  }

  getAuthMe() {
    return this.http.get<any>(this.url + `auth/me`);
  }

  private guardarToken(idToken: string, time: string) {
    localStorage.setItem('token', idToken);
    localStorage.setItem('expiraEn', time);
  }

  obtenerToken() {
    this.userToken = localStorage.getItem('token');
  }

  estaAutenticado() {
    this.obtenerToken();
    if (!this.userToken || this.userToken.length == 0) {
      return false;
    }

    return this.loginTiempoValido()
  }

  loginTiempoValido() {
    // Valido tiempo del token
    const expiraEn = new Date(localStorage.getItem('expiraEn') as string);
    const ahora = new Date();

    return expiraEn > ahora;
  }


  expulsar() {
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['auth']);
  }

  
}
