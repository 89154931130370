import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

const SECRET_KEY = 'Apw5n6';

@Injectable({
  providedIn: 'root'
})
export class EncriptarService {

  salt: string;

  constructor() {
    this.salt = '8dfmdsm454jbb4b35';
  }

  public encrypt(data: any){
    data = this.prapararEncrypt(data)
    data = CryptoJS.AES.encrypt(data, SECRET_KEY);
    data = data.toString();
    return data;
  }

  public decrypt(data: any){
    data = CryptoJS.AES.decrypt(data, SECRET_KEY);
    data = data.toString(CryptoJS.enc.Utf8);
    data = this.prepararDecrypt(data)
    return data;
  }

  prapararEncrypt(o: any) {
    o = JSON.stringify(o).split('');
    for(var i = 0, l = o.length; i < l; i++)
        if(o[i] == '{')
            o[i] = '}';
        else if(o[i] == '}')
            o[i] = '{';
    return encodeURI(this.salt + o.join(''));
  }

 prepararDecrypt(o: any) {
    o = decodeURI(o);
    if(this.salt && o.indexOf(this.salt) != 0)
        throw new Error('object cannot be decrypted');
    o = o.substring(this.salt.length).split('');
    for(var i = 0, l = o.length; i < l; i++)
        if(o[i] == '{')
            o[i] = '}';
        else if(o[i] == '}')
            o[i] = '{';
    return JSON.parse(o.join(''));
}

}
