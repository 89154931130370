import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { ToastService } from '../services/toast-service/toast.service';
import { Router } from '@angular/router';
import { LoginService } from '../services/login-service/login.service';

@Injectable({
  providedIn: 'root',
})
export class ResponseErrorsInterceptor implements HttpInterceptor {

  constructor(
    private toastService: ToastService,
    private router: Router,
    private loginService: LoginService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(
        tap((response) => {
          if (response instanceof HttpResponse) {
            if (response.body.status && response.body.status == 'error') {
              this.toastService.showErrorToast('', response.body.mensaje_error);
              console.error({ ...response.body, url: response.url });
            }
          }
        }),
        catchError((error: HttpErrorResponse) => {
          let mensajeSnackbar = '';
          if (!window.navigator.onLine) {
            mensajeSnackbar = `Su navegador no esta conectado a internet.`;
          } else if (error.status == 401) {
            mensajeSnackbar = error.error.mensaje_error;
            this.loginService.expulsar();
          }

          if (mensajeSnackbar !== '') this.toastService.showErrorToast('', mensajeSnackbar);

          return throwError(() => { return error.error })
        })
      );
  }
}