import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EncriptarService } from '../encriptar-service/encriptar.service';
import { LoginService } from '../login-service/login.service';

@Injectable({
  providedIn: 'root'
})
export class PermisoService {

  // Usuarios
  public usuarioVer: boolean = false;
  public usuarioCrear: boolean = false;
  public usuarioEditar: boolean = false;
  public usuarioEliminar: boolean = false;

  // Empresas
  public empresaVer: boolean = false;
  public empresaCrear: boolean = false;
  public empresaEditar: boolean = false;
  public empresaEliminar: boolean = false;

  // Vendedores
  public vendedorVer: boolean = false;
  public vendedorCrear: boolean = false;
  public vendedorEditar: boolean = false;
  public vendedorEliminar: boolean = false;

  // Prestamos
  public prestamoVer: boolean = false;
  public prestamoCrear: boolean = false;
  public prestamoEditar: boolean = false;
  public prestamoEliminar: boolean = false;

  // Esquemas
  public esquemaVer: boolean = false;
  public esquemaCrear: boolean = false;
  public esquemaEditar: boolean = false;
  public esquemaEliminar: boolean = false;

  // Lineas
  public lineaVer: boolean = false;
  public lineaCrear: boolean = false;
  public lineaEditar: boolean = false;
  public lineaEliminar: boolean = false;

  // Liquidaciones
  public liquidacionVer: boolean = false;
  public liquidacionCrear: boolean = false;
  public liquidacionAnular: boolean = false;

  // Movimientos
  public movimientosVer: boolean = false;
  public movimientosCrear: boolean = false;
  public movimientosEditar: boolean = false;

  public permisos: any = {};
  public roles: {rol: string, nivel: number}[] = [];


  constructor(
    private loginService: LoginService,
    private encriptarService: EncriptarService
  ) { }

  actualizarPermisos() {
    this.loginService.getAuthMe().subscribe((resp) => {
      if (resp.status == "ok") {
        this.permisos = resp.usuario.permisos
        this.roles = resp.usuario.roles
        this.encriptarPermisos()

        // Borrar y refactor de booleans
        // Usuarios
        this.usuarioVer = resp.usuario.permisos.usuarios_ver;
        this.usuarioCrear = resp.usuario.permisos.usuarios_crear;
        this.usuarioEditar = resp.usuario.permisos.usuarios_editar;
        this.usuarioEliminar = resp.usuario.permisos.usuarios_eliminar;

        // Empresas
        this.empresaVer = resp.usuario.permisos.empresas_ver;
        this.empresaCrear = resp.usuario.permisos.empresas_crear;
        this.empresaEditar = resp.usuario.permisos.empresas_editar;
        this.empresaEliminar = resp.usuario.permisos.empresas_eliminar;

        // Vendedores
        this.vendedorVer = resp.usuario.permisos.vendedores_ver;
        this.vendedorCrear = resp.usuario.permisos.vendedores_crear;
        this.vendedorEditar = resp.usuario.permisos.vendedores_editar;
        this.vendedorEliminar = resp.usuario.permisos.vendedores_eliminar;

        // Prestamos
        this.prestamoVer = resp.usuario.permisos.prestamos_ver;
        this.prestamoCrear = resp.usuario.permisos.prestamos_crear;
        this.prestamoEditar = resp.usuario.permisos.prestamos_editar;
        this.prestamoEliminar = resp.usuario.permisos.prestamos_eliminar;

        // Esquemas
        this.esquemaVer = resp.usuario.permisos.esquemas_ver;
        this.esquemaCrear = resp.usuario.permisos.esquemas_crear;
        this.esquemaEditar = resp.usuario.permisos.esquemas_editar;
        this.esquemaEliminar = resp.usuario.permisos.esquemas_eliminar;


        // Lineas
        this.lineaVer = resp.usuario.permisos.lineas_ver;
        this.lineaCrear = resp.usuario.permisos.lineas_crear;
        this.lineaEditar = resp.usuario.permisos.lineas_editar;
        this.lineaEliminar = resp.usuario.permisos.lineas_eliminar;

        // Liquidaciones
        this.liquidacionVer = resp.usuario.permisos.liquidaciones_ver;
        this.liquidacionCrear = resp.usuario.permisos.liquidaciones_crear;
        this.liquidacionAnular = resp.usuario.permisos.liquidaciones_anular;

        // Movimientos
        this.movimientosVer = resp.usuario.permisos.movimientos_ver;
        this.movimientosCrear = resp.usuario.permisos.movimientos_crear;
        this.movimientosEditar = resp.usuario.permisos.movimientos_editar;
      } else {
        console.log("No se pudo cargar los permisos.");
      }
    })
  }

  encriptarPermisos(){
    var permisosEncypt = this.encriptarService.encrypt(this.permisos);
    this.guardarPermisos(permisosEncypt)
  }

  private guardarPermisos(permisos: any) {
    localStorage.setItem('permisos', JSON.stringify(permisos));
  }
}
