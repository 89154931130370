import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { isEmpty, Observable } from 'rxjs';
import { LoginService } from '../services/login-service/login.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(
    private loginService: LoginService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let token = this.obtenerToken();
    if (token != '') {
      if (request.body instanceof FormData) {
        request = request.clone({
          setHeaders: {
            'Authorization': `Bearer ${token}`,
          }
        })
      } else {
        request = request.clone({
          setHeaders: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          }
        })
      }
    }

    return next.handle(request);
  }

  private obtenerToken() {
    return localStorage.getItem('token');
  }
}
