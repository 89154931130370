import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginGuard } from './guard/login/login.guard';

const routes: Routes = [
  //{ path: '**', redirectTo: 'auth' },
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  {
    path: 'scc',
    loadChildren: () => import('./scc/scc.module').then(m=> m.SccModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
